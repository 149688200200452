@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
  --color-papaya: #E24E42;
  --color-mustard: #E9B000;
  --color-blush: #EB6E80;
  --color-aqua: #008F95;

  --color-blueberry: #6B7A8F;
  --color-apricot: #F7882F;
  --color-citrus: #F7C331;
  --color-applecore: #DCC7AA;
}

body {
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-applecore);
}

.logo-generator {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  flex-grow: 1;
  background-color: #FFF;
  padding-left: 50px;
  padding-right: 50px;
}
/*
.container > * {
  flex-grow: 1;
}*/

.app-header {
  background-color: var(--color-papaya);
  text-align: center;
  color: #FFF;
}



.app-body {
  display: flex;
  flex-grow: 1;
}

.user-input {
  padding: 30px;
  background-color: #FFF;
  width: 700px;
  max-width: 100%;
  border: 2px solid var(--color-aqua);
  box-shadow: 3px 3px 5px var(--color-aqua);
}

.user-input label {
  color: #000;
}

.user-input .autocomplete-input {
  display: flex;
  flex-direction: column;
}

.user-input input, .user-input textarea {
  padding: 10px;
  font-size: 16px;
}

.user-input label {
  margin-bottom: 5px;
  margin-top: 12px;
}

.user-input button {
  padding: 10px 30px;
  margin-top: 20px;
  background-color: var(--color-papaya);
  color: #FFF;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.user-input .form-element {
  display: flex;
  flex-direction: column;
}

.user-input .autocomplete-input {
  position: relative;
}

.user-input .autocomplete-input ul {
  background-color: #FFF;
  position: absolute;
  padding: 10px;
  top: 6px;
  width: 200px;
}


.loading-component {
  position: absolute;
  top: 50%;
  left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-component .loading-inner {
display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
    padding: 20px;
    border: 2px solid var(--color-aqua);
    box-shadow: 3px 3px 5px var(--color-aqua);
}

.loading-component .completed-steps ul {
  padding-left: 0;
  list-style: none;
}


.logo-display {
  margin-top: 100px;
}

.logo-display img {
  border: 1px solid #000;
  box-shadow: 2px 2px 3px rgba(0,0,0,0.3);
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.image-grid {
  grid-template-columns: repeat(4, 1fr);
}

.logo-display {
  width: 100%;
}


@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.dot {
  animation: blink 1.4s infinite;
  display: inline-block;
}